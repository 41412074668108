import { DisplayCourier, isDisplayCourier, validateDisplayCourier } from "./courier";
import { isString, isBool } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayShippingAccount {
    id: string
    name: string
    number: string
    postal?: string
    active: boolean
    courier: DisplayCourier
}

// Type guards
export const isDisplayShippingAccount = (shippingAccount: unknown): shippingAccount is DisplayShippingAccount => {
    if (
        typeof shippingAccount !== 'object' ||
        !('id' in shippingAccount) ||
        !('name' in shippingAccount) ||
        !('number' in shippingAccount) ||
        !('active' in shippingAccount) ||
        !('courier' in shippingAccount)
    ) {
        return false;
    }

    return (
        isString(shippingAccount.id) &&
        isString(shippingAccount.name) &&
        isString(shippingAccount.number) &&
        (!('postal' in shippingAccount) || shippingAccount.postal === undefined || isString(shippingAccount.postal)) &&
        isBool(shippingAccount.active) &&
        isDisplayCourier(shippingAccount.courier)
    );
}

// Validators
export const validateDisplayShippingAccount = (shippingAccount: DisplayShippingAccount) => {
    validateNonEmptyString(shippingAccount.id);
    validateNonEmptyString(shippingAccount.number);
    if (shippingAccount.postal) {
        validateNonEmptyString(shippingAccount.postal);
    }
    validateDisplayCourier(shippingAccount.courier);
}
